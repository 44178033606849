import { APISlice } from 'app/store/APISlice';
import { ServiceEnum } from 'common';
import { IUserInfo } from 'pages/Authentication/entities';
import { MFASecret } from '../entities';

export const extendedApiSlice = APISlice.injectEndpoints({
    endpoints: builder => ({
        favoriteBSARound: builder.mutation<any, IUserFavoritesQueryParams>({
            query: ({ validationId, BSARoundId, isClickRemove }) => ({
                url: `${ServiceEnum.Auth}/FavoriteBSARound`,
                method: 'POST',
                body: {
                    validationId,
                    BSARoundId,
                    isClickRemove,
                },
                responseHandler: response => response.text(),
            }),
        }),
        editBSARoundName: builder.mutation<any, IUserEditBSARoundQueryParams>({
            query: ({ roundName, bstId }) => ({
                url: `${ServiceEnum.Auth}/EditBlindSpotRoundName`,
                method: 'POST',
                body: {
                    roundName,
                    bstId,
                },
            }),
        }),
        generateQRCode: builder.query<{ secret: MFASecret; qr: string }, string>({
            query: email => ({
                url: `${ServiceEnum.Auth}/GenerateQRCode`,
                params: { email },
            }),
        }),
        addActivityLog: builder.mutation<{ type: string; action: string; item_id: string }, any>({
            query: ({ type, action, item_id }) => ({
                url: `${ServiceEnum.Auth}/AddActivityLog`,
                params: { type, action, item_id },
                method: 'GET',
            }),
            invalidatesTags: (result, error, arg) => [
                'AnnotationList',
                'AnnotationProjectList',
                { type: 'AnnotationList', id: result?.item_id },
            ], 
        }),
        disableMultiFactorAuth: builder.mutation<any, string>({
            query: password => ({
                url: `${ServiceEnum.Auth}/DisableMultiFactorAuth`,
                method: 'POST',
                body: {
                    password,
                },
            }),
        }),
        activateMultiFactorAuth: builder.mutation<{ user: IUserInfo }, { mfaSecret: string; code: string; email: string }>({
            query: body => ({
                url: `${ServiceEnum.Auth}/ActivateMultiFactorAuth`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const {
    useFavoriteBSARoundMutation,
    useEditBSARoundNameMutation,
    useGenerateQRCodeQuery,
    useActivateMultiFactorAuthMutation,
    useDisableMultiFactorAuthMutation,
    useAddActivityLogMutation,
} = extendedApiSlice;

interface IUserFavoritesQueryParams {
    validationId: string;
    BSARoundId: string;
    isClickRemove?: boolean;
}

interface IUserEditBSARoundQueryParams {
    roundName: string;
    bstId: string;
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthStateFactory, IUserInfo } from '../entities';
import { ISetupConfigModalState } from '../entities/interfaces';
import { ILoginResponsePayload } from '../entities/payload';

export const AuthSlice = createSlice({
    name: 'Login',
    initialState: AuthStateFactory(),
    reducers: {
        loginUserAction(state, action: PayloadAction<Partial<ILoginResponsePayload>>) {
            state.isAppInit = true;
            state.token = action.payload.token;
            state.user = action.payload.user;
            state.isActiveMFA = !!action.payload?.mfaToken;
            state.mfaTokenStatus= action.payload?.mfaTokenStatus;
            state.setupModal.visible = action.payload?.setupFirstTime;
        },
        setAppInit(state, action) {
            state.isAppInit = action.payload;
        },
        setLoginLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        updateToken(state, action: PayloadAction<string>) {
            state.token = action.payload;
        },
        updateUserAccountInfo(state, action: PayloadAction<IUserInfo>) {
            state.user = action.payload;
            state.isActiveMFA = !!action.payload?.mfaSecret;
        },
        updateUserMFAState(state, action: PayloadAction<boolean>) {
            state.isActiveMFA = action.payload;
            if(!action.payload) state.mfaTokenStatus = "";
        },
        updateActivationToken(state, action: PayloadAction<string>) {
            state.activation_token = action.payload;
        },
        updateActivationStatus(state, action: PayloadAction<'expired' | 'valid'>) {
            state.activationExpired = action.payload;
        },
        updateSetupModal(state, action: PayloadAction<ISetupConfigModalState>) {
            state.setupModal = action.payload;
        },
    },
});

export const {
    loginUserAction,
    setAppInit,
    setLoginLoading,
    updateToken,
    updateUserAccountInfo,
    updateActivationToken,
    updateActivationStatus,
    updateUserMFAState,
    updateSetupModal,
} = AuthSlice.actions;

export default AuthSlice.reducer;

import { AnnotationAgreementTabs, IImageResponse, ProblemTypeKeys } from 'common';

export interface IAgreementFields {
    irr_type?: AnnotationAgreementTabs;
    annotation_id: string;
    comparison_mode?: 'ovo' | 'ovr' | 'all' | 'image_inspection' | 'intra_rating' | 'image_inspection_classification' | any;
    problem_type?:
        | 'study_measurement'
        | 'study_polygon'
        | 'vendor_metrics'
        | 'object_detection'
        | 'segmentation'
        | 'classification'
        | 'semantic_segmentation'
        | any;
    study_id?: string | Array<string>;
    metric_name?: string;
    user_id?: string | Array<string>;
    options?: { image_inspection: boolean };
    user_id1?: string;
    user_id2?: string;
    dataset_id?: string;
    image_id?: string;
    plot_type?: 'scatterplot' | 'classification_radar' | 'classification_bar' | 'hausdorff' | 'dice' | 'area' | any;
    image_inspection?: boolean;
    vendor_metrics?: boolean;
    object_name?: string;
}

export interface IAnnotationAgreement {
    _id: string;
    irr_type: string;
    problem_type: ProblemTypeKeys;
    shape_type: string;
    image_inspection: boolean;
    image_id: string;
    image_id1: string;
    image_id2: string;
    image_details: IImageResponse;
    image_details1: IImageResponse;
    image_details2: IImageResponse;
    result: IAgreementResult[];
    user_comb: string;
}

export interface IAgreementResult {
    score: number;
    user_comb: string;
    metric_name: string;
}

export interface IIntraRatingResponse {
    metric_name: string;
    score: number;
}

export interface IImageInpectionRes {
    image_id: string;
}

export interface Mdc {
    base_user: string;
    study_id: string;
    result: MdcResult[];
}

export interface MdcResult {
    target_user: string;
    score: FluffyScore;
}

export interface FluffyScore {
    measurement: Dictionary;
    object_name: Dictionary;
    data: {
        sem: number;
        mdc: number;
    };
}

export interface DiceResponsePayload {
    data: Dictionary[];
    object_list: string[];
    slice_list: string[];
    reader_id_list: string[];
}

export enum MetricLevelEnum {
    VOLUME = 'volume',
    AREA = 'area',
}

export const MetricLevelMap = {
    [MetricLevelEnum.VOLUME]: 'Volume',
    [MetricLevelEnum.AREA]: 'Area',
};

export enum HausdorffMetricEnum {
    HAUSDORFF = 'hausdorff',
    AVG_HAUSDORFF = 'avg-hausdorff',
}
export const HausdorffMetricMap = {
    [HausdorffMetricEnum.HAUSDORFF]: 'Hausdorff',
    [HausdorffMetricEnum.AVG_HAUSDORFF]: 'Avg. Hausdorff',
};

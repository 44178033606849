import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Space, Typography } from 'antd';
import { GInput } from 'components/GInput';
import React, { useState } from 'react';
import Styles from "./Styles.module.scss";

export default function NewItemInput({ value, onChange }: IProps) {
    const [input, setInput] = useState<string>();

    const onAddNewItem = () => {
        if (!input?.trim()) return;
        const newValue = Array.isArray(value) ? [...value, input] : [input];
        onChange(newValue, undefined);
        setInput("");
    };

    const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => e.stopPropagation();

    return (
        <Space
            align="center"
            className={Styles.Wrapper}
        >
            <GInput
                placeholder="Please enter item"
                onInputChange={setInput}
                onKeyDown={handleKeyDown}
                value={input}
                id="addNewItem"
            />
            <Typography.Link onClick={onAddNewItem}>
                <div className={Styles.AddItem}>
                    <PlusOutlined /> Add item
                </div>
            </Typography.Link>
        </Space>
    );
}

export interface IProps {
    value: any;
    onChange: (value: any, option: any) => void;
}
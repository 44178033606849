import { IDataset, IDatasetResponsePayload, TransformDataset } from 'common/entities';
import { getMessages } from 'common/helpers/MLHelper';
import { AnnotationShapeType, AnnotationTool } from './annotation.interface';
import { CompletionStatus } from './IAnnotationStatus';
import { AnnotationModes, LabelingStrategies, SamplingStrategies } from './INewProjectState';
import {
    ILabelingConfiguration,
    ILabelingConfigurationResponse,
    LabelingConfigurationFactory,
    transformLabelingConfigurationForRequest,
} from './LabelingConfiguration';

export interface IAnnotationTracking {
    datasetId: string;
    annotationId: string;
    annotationName: string;
    createdAt: number;
    updatedAt: number;
    userId: string;
    status?: TAnnotationStatus;
    projectStatus?: IAnnotationProjectStatus;
    description?: string;
    instructions?: string;
    team: Team;
    aiAssistanceConfig?: IAiAssistanceConfig;
    isApproveNeeded?: boolean;
    preAnnotationList?: Array<string>;
    dicomViewer?: boolean;
    imageEvaluation?: IImageEvaluation;
    labelingConfiguration?: Array<ILabelingConfiguration>;
    measurementFormList?: Array<IMeasurementForm>;
    schemaVersion?: string;
    defaultReader?: string;
    colorMap?: Dictionary<string>;
    review_required?: boolean;
    generatedAnnotationAgreement: IAgreementStatus;
    tags?: Array<string>;
    dataset?: IDataset;
    isStudyType?: boolean;
    rateAnnotations?: boolean;
    batch?: Batch;
    defaultViewer: AnnotationViewerType;
    agrementMetricMap?: AggrementMetricMap;
    lastActivity?: number;
    projectStartDate?: number;
    baseAnnotation?: boolean;
    targetCompletionDate?: number;
    targetCompletionDateMap?: Dictionary<number>;
    targetReviewDate?: number;
    targetReviewDateMap?: Dictionary<number>;
    reviewStartDate?: number;
    localBackup?: boolean;
    labelGroup?: Array<ILabelGroup>;
    backupConfig?: Dictionary<string>;
    metricTargetMap?: Dictionary<MetricTarget>;
    stageList?: Array<Stage>;
    active_stage?: StageType;
    comparedProject?: Array<string>;
}

export interface IAnnotationTrackingResponse {
    dataset_id: string;
    created_timestamp: number;
    last_updated_timestamp: number;
    annotation_id: string;
    annotation_name: string;
    user_id: string;
    description?: string;
    instructions?: string;
    assigned_to?: Array<string>;
    status?: TAnnotationStatus;
    project_status?: IAnnotationProjectStatus;
    view_count?: number;
    team: Team;
    ai_assistance_config?: IAiAssistanceConfig;
    is_approve_needed?: boolean;
    pre_annotation_list?: Array<string>;
    dicom_viewer?: boolean;
    image_evaluation?: IImageEvaluation;
    labeling_configuration?: Array<ILabelingConfigurationResponse>;
    measurement_form_list?: Array<IMeasurementForm>;
    schema_version?: string;
    default_reader?: string;
    color_map?: Dictionary<string>;
    review_required?: boolean;
    created_at: number;
    generated_annotation_agreement: IAgreementStatus;
    tags?: Array<string>;
    dataset?: IDatasetResponsePayload;
    isStudyType?: boolean;
    rate_annotations?: boolean;
    batch?: Batch;
    default_viewer?: AnnotationViewerType;
    agreement_metric_map?: AggrementMetricMap;
    last_activity?: number;
    project_start_date?: number;
    base_annotation?: boolean;
    target_completion_date?: number;
    target_completion_date_map?: Dictionary<number>;
    target_review_date?: number;
    target_review_date_map?: Dictionary<number>;
    review_start_date?: number;
    local_backup?: boolean;
    label_group?: Array<ILabelGroup>;
    backup_config?: Dictionary<string>;
    dice_target?: Dictionary<string>;
    icc_target?: Dictionary<string>;
    metric_target_map?: Dictionary<MetricTarget>;
    stage_list?: Array<Stage>;
    active_stage?: StageType;
    compared_project?: Array<string>;
}

export function getAnnotationTrackingData(data?: IAnnotationTrackingResponse): IAnnotationTracking {
    return {
        annotationId: data?.annotation_id,
        annotationName: data?.annotation_name,
        backupConfig: data?.backup_config,
        datasetId: data?.dataset_id,
        description: data?.description,
        instructions: data?.instructions,
        createdAt: data?.created_timestamp,
        updatedAt: data?.last_updated_timestamp,
        userId: data?.user_id,
        status: data?.status,
        projectStatus: data?.project_status,
        team: data?.team ?? [],
        aiAssistanceConfig: AiAssistanceConfigFactory(data?.ai_assistance_config),
        isApproveNeeded: data?.is_approve_needed,
        preAnnotationList: data?.pre_annotation_list,
        dicomViewer: data?.dicom_viewer,
        imageEvaluation: data?.image_evaluation,
        labelingConfiguration: data?.labeling_configuration?.map(LabelingConfigurationFactory),
        measurementFormList: data?.measurement_form_list,
        schemaVersion: data?.schema_version,
        defaultReader: data?.default_reader,
        colorMap: data?.color_map,
        review_required: data?.review_required,
        generatedAnnotationAgreement: data?.generated_annotation_agreement,
        tags: data?.tags,
        dataset: TransformDataset(data?.dataset),
        isStudyType: data?.isStudyType,
        rateAnnotations: data?.rate_annotations,
        batch: data?.batch ?? [],
        defaultViewer: data?.default_viewer ?? '2D',
        agrementMetricMap: data?.agreement_metric_map,
        lastActivity: data?.last_activity,
        projectStartDate: data?.project_start_date,
        baseAnnotation: data?.base_annotation,
        stageList: data?.stage_list,
        active_stage: data?.active_stage,
        targetCompletionDate: data?.target_completion_date,
        targetCompletionDateMap: data?.target_completion_date_map,
        localBackup: data?.local_backup ?? true,
        labelGroup: data?.label_group,
        metricTargetMap: data?.metric_target_map,
        reviewStartDate: data?.review_start_date,
        targetReviewDate: data?.target_review_date,
        targetReviewDateMap: data?.target_review_date_map,
        comparedProject: data?.compared_project,
    };
}

export function transformDataForRequest(data: Partial<IAnnotationTracking>): IAnnotationTrackingResponse {
    return {
        annotation_id: data.annotationId,
        annotation_name: data.annotationName,
        dataset_id: data.datasetId,
        description: data.description,
        instructions: data.instructions,
        created_timestamp: data.createdAt,
        last_updated_timestamp: data.updatedAt,
        user_id: data.userId,
        status: data.status,
        project_status: data.projectStatus,
        team: data.team,
        ai_assistance_config: data.aiAssistanceConfig,
        is_approve_needed: data.isApproveNeeded,
        pre_annotation_list: data?.preAnnotationList,
        image_evaluation: data?.imageEvaluation,
        labeling_configuration: data?.labelingConfiguration?.map(transformLabelingConfigurationForRequest),
        measurement_form_list: data?.measurementFormList,
        schema_version: data?.schemaVersion,
        default_reader: data?.defaultReader,
        color_map: data?.colorMap,
        review_required: data?.review_required,
        created_at: data?.createdAt,
        generated_annotation_agreement: data?.generatedAnnotationAgreement,
        tags: data?.tags,
        rate_annotations: data?.rateAnnotations,
        batch: data?.batch,
        agreement_metric_map: data?.agrementMetricMap,
        default_viewer: data?.defaultViewer,
        last_activity: data?.lastActivity,
        project_start_date: data?.projectStartDate,
        base_annotation: data?.baseAnnotation,
        stage_list: data?.stageList,
        active_stage: data?.active_stage,
        target_completion_date: data?.targetCompletionDate,
        local_backup: data?.localBackup ?? true,
        label_group: data?.labelGroup,
        metric_target_map: data?.metricTargetMap,
        review_start_date: data?.reviewStartDate,
        target_review_date: data?.targetReviewDate,
        target_review_date_map: data?.targetReviewDateMap,
        compared_project: data?.comparedProject,
    };
}

export interface Stage {
    stage_id: string;
    type: StageType;
    description?: string;
    start_date: number;
    target_end_date: number;
    label_targets?: Dictionary<{ start: number; end: number }>;
}
export type StageType = 'annotation' | 'qc' | 'review' | 'data_qc';

export const stageLabelMap: Record<StageType, string> = {
    annotation: getMessages('000020'),
    qc: getMessages('000707'),
    review: getMessages('000698'),
    data_qc: getMessages('000525'),
};

export interface MetricTarget {
    icc: number;
    dice: number;
}
export enum AnnotationProjectStatus {
    Active = 'active',
    Project = 'project',
}

export interface ILabelGroup {
    group_id: string;
    name: string;
    label_list: Array<string>;
    icon: string;
    date_range?: [number, number];
    status?:"completed" | "inprogress" | "notstarted";
}

export type TAnnotationStatus = 'project' | 'active';
export type IAnnotationProjectStatus = 'inprogress' | 'inreview' | 'completed';
export type AnnotationViewerType = '2D' | '3D';

export enum ProjectStatus {
    InProgress = 'inprogress',
    Completed = 'completed',
    InReview = 'inreview',
}

export type Team = Array<ITeamMember>;
export interface ITeamMember {
    id: string;
    role: AssignmentRoleMap;
    assignment: Array<string>;
    review_assignment?: Array<string>;
    statusMap?: Dictionary<CompletionStatus>;
    email?: string;
    displayName?: string;
}
export interface ITeamStudy {
    id: string;
    role: AssignmentRoleMap;
    progress: number;
    assignment: Array<string>;
}

export interface IAnalyticItem {
    userId: string;
    email: string;
    displayName: string;
    organization: string;
    assignmentCount: number;
    completedCount?: number;
    progress?: number;
}

export interface IImageStatistics {
    alias?: string;
    description?: string;
    duration?: number;
    status: string;
    reviewStatus: string;
    createdAt: number;
    updatedAt: number;
    image_id: string;
    study_id: string;
    completion: string;
    completedCount: number;
}

export enum AssignmentRole {
    Annotator = 'annotator',
    Reviewer = 'reviewer',
    Manager = 'manager',
    Guest = 'guest',
    DataQC = 'data_qc',
}

export type AssignmentRoleMap = Record<AssignmentRole, boolean>;

export enum AggrementMetrics {
    SEM = 'sem',
    DICE = 'dice',
    ICC = 'icc',
    ICC_MULTIPLE = 'icc_multiple',
    BLAND_ALTMAN = 'bland_altman',
    BLAND_ALTMAN_PLOT = 'bland_altman_plot',
    SCORE_BAR = 'score_bar',
    BOX_PLOT = 'box_plot',
}

export type AggrementMetricMap = Record<AggrementMetrics, boolean>;

export type TSamplingStrategy = keyof typeof SamplingStrategies;
export type TLabelingStrategy = keyof typeof LabelingStrategies;
export type TAnnotationMode = keyof typeof AnnotationModes;

export function TeamMemberFactory(data?: Partial<ITeamMember>): ITeamMember {
    return {
        id: data?.id ?? null,
        role: data?.role ?? {
            annotator: false,
            reviewer: false,
            manager: false,
            guest: false,
            data_qc: false,
        },
        assignment: data?.assignment ?? [],
    };
}

export function AiAssistanceConfigFactory(data?: Partial<IAiAssistanceConfig>): IAiAssistanceConfig {
    return {
        enabled: data?.enabled ?? false,
        model_list: data?.model_list ?? [],
    };
}

export interface IAiAssistanceConfig {
    enabled: boolean;
    model_list: Array<IAiAssistanceConfigItem>;
}

export interface IAiAssistanceConfigItem {
    model_id: string;
    configuration: Record<number, number>;
}

export interface IMeasurementForm {
    id?: string;
    name: string;
    object_list: Array<string>;
    measurement_list: Array<IMeasurementSetItem>;
    preferred_series?: string;
}
export interface IMeasurementSetItem {
    id: string;
    name: string;
    label: string;
    shape_type: AnnotationShapeType;
    default_tool?: AnnotationTool;
}

export interface IAgreementStatus {
    inter_rating: AnnotationGeneratedStatus;
    intra_rating: AnnotationGeneratedStatus;
    image_inspection: AnnotationGeneratedStatus;
    vendor_metrics: AnnotationGeneratedStatus;
    visual_comparison: AnnotationGeneratedStatus;
    label_comparison: AnnotationGeneratedStatus;
}

export type IAgreementTabs = keyof IAgreementStatus;

export enum AgreementTabs {
    InterRating = 'inter_rating',
    IntraRating = 'intra_rating',
    ImageInspection = 'image_inspection',
    VendorMetrics = 'vendor_metrics',
    VisualComparison = 'visual_comparison',
    LabelComparison = 'label_comparison',
}
export interface AnnotationGeneratedStatus {
    status: AgreementStatusEnum;
    created_timestamp: number;
}
export enum AgreementStatusEnum {
    DISABLE = 0,
    LOADING = -1,
    GENERATED = 1,
}

export interface IAnnotatorSummary {
    approved: number;
    rejected: number;
    assignmentCount: number;
    completed: number;
    inprogress: number;
    name: string;
    pending: number;
    userId: string;
    fixed?: number;
    unreviewed?: number;
}

export type IStudyPair = { primary_study: string; secondary_study: string };
export interface IImageEvaluation {
    pair_info: IPairInfo;
    table_list: Array<IEvaluationTable>;
}

export interface IEvaluationTable {
    id: string;
    name: string;
    question_groups: Array<IQuestionGroup>;
}

export interface IPairInfo {
    primary_study_title: string;
    secondary_study_title: string;
    distinguishing_metadata: string;
    pair_assignments: Array<IStudyPair>;
}

export interface IQuestionGroup {
    id: string;
    group_name: string;
    questions: Array<IQuestion>;
}

export interface IQuestion {
    id: string;
    question: string;
}
export type Batch = Array<IBatch>;
export interface IBatch {
    name: string;
    study_list?: Array<string>;
    batch_size?: number;
}

import Styles from './Styles.module.scss';
import useSystemUtils from './useSystemUtils';
import GProgress from 'components/GProgress';

export default function SystemUtilsOverlay() {
    const { progressMenuList } = useSystemUtils();

    return (
        <div className={Styles.ProgressContainer}>
            {progressMenuList.map(item => (
                <ProgressComponent
                    key={item.label}
                    label={item.label}
                    value={item.value}
                />
            ))}
        </div>
    );
}

function ProgressComponent({ label, value }: { label: string; value: number }) {
    return (
        <div className={Styles.ProgressBar}>
            <span className={['caption2-bold', Styles.Progresslabel].join(' ')}>{label}</span>
            <GProgress
                percent={value}
            />
        </div>
    );
}


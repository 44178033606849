import { PayloadAction } from '@reduxjs/toolkit';
import { subscribeDB } from 'app/store/actions';
import { AxiosResponse } from 'axios';
import { AuthService, _uuid } from 'common';
import { IAuthState } from 'pages/Authentication/entities';
import { ILoginRequestPayload, ILoginResponsePayload } from 'pages/Authentication/entities/payload';
import { call, put } from 'redux-saga/effects';
import { loginUserAction, setLoginLoading, updateActivationStatus, updateActivationToken } from '..';

export function* userLoginActionSaga(data: Partial<IAuthState>, rememberMe?: boolean): any {
    if (data) {
        sessionStorage.setItem('accessToken', data.token);
        sessionStorage.setItem('user', JSON.stringify(data.user));

        if (rememberMe) {
            localStorage.setItem('accessToken', data.token);
            localStorage.setItem('rememberMe', 'true');
        }

        let mfaTokenStatus = '';
        let mfaToken = false;

        if (data?.user?.mfaEnabled) {
            mfaTokenStatus = 'enabled';
            mfaToken = true;
        }

        yield put(loginUserAction({ ...data, mfaTokenStatus, mfaToken }));
        yield put(subscribeDB());
    }
}

export function* userLoginTransactionSaga({ payload }: PayloadAction<ILoginRequestPayload>): any {
    const eventId = _uuid();

    try {
        yield put(setLoginLoading(true));

        const response: AxiosResponse<ILoginResponsePayload> = yield AuthService.Login.call(payload.loginRequest, {
            headers: {
                'x-activation-token': payload.activationToken,
                'x-request-id': eventId,
            },
        });

        if (response?.data?.setupFirstTime) {
            yield put(
                loginUserAction({ user: { email: payload.loginRequest.email }, token: '', mfaToken: '', setupFirstTime: true })
            );
            sessionStorage.setItem('accessToken', response?.data?.tempToken);
        }

        if (response?.data?.mfaToken === 'enabled') {
            yield put(loginUserAction({ user: {}, token: '', mfaTokenStatus: 'enabled', mfaToken: true}));
        }
        if (response?.data?.mfaToken === 'forced') {
            yield put(loginUserAction({ user: {}, token: '', mfaTokenStatus: 'forced', mfaToken: true}));
        }

        if (response?.data?.user) {
            yield call(userLoginActionSaga, response?.data, payload.rememberMe);
        }
    } catch (e: any) {
        console.log(e);
        if (e?.data?.message === '$00014') {
            yield put(updateActivationToken(''));
            yield put(updateActivationStatus('expired'));
        }
        if (e?.data?.message === '$00017') {
            yield put(loginUserAction({ user: {}, token: '', mfaToken: '' }));
        }
    } finally {
        yield put(setLoginLoading(false));
    }
}

import { Input, InputProps, InputRef } from 'antd';
import cx from "classnames";
import React, { useCallback } from 'react';
import Styles from './assets/Styles.module.scss';

export function GInput({ className, onInputChange, onChange, inputRef, ...props }: GInputProps) {
    const onChangeValue = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onInputChange?.(e.target?.value, e);
            onChange?.(e);
        },
        [onChange, onInputChange]
    );

    return (
        <div className={cx(Styles.GInputContainer, className)}>
            <Input
                ref={inputRef}
                onChange={onChangeValue}
                {...props}
            />
        </div>
    );
}

interface GInputProps extends InputProps {
    onInputChange?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
    inputRef?: React.Ref<InputRef>;
}

export * from './GInputGroup';

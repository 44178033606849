import { Button, ButtonProps } from 'antd';
import cs from 'classnames';
import React from 'react';
import Styles from './Styles.module.scss';

export default function GButton({
    type = 'primary',
    size = 'middle',
    className,
    endIcon,
    children,
    style,
    hidden,
    ...props
}: GButtonProps) {
    if (hidden) return null;
    return (
        <div
            className={cs(Styles.GButton, className)}
            style={style}
        >
            <Button
                {...props}
                className={cs(type, size)}
            >
                {endIcon ? <span className='end-icon'>{endIcon}</span> : null}
                <div className='text'>{children}</div>
            </Button>
        </div>
    );
}

interface GButtonProps extends ButtonProps {
    type?: 'primary' | 'default' | 'ghost' | 'link' | 'text' | 'dashed';
    endIcon?: React.ReactNode;
    size?: 'large' | 'middle' | 'small';
    hidden?: boolean;
}

export const GButtonClassname = Styles.GButton;

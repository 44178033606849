import { ProgressProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export default function GProgress({ className,percent = 0, ...props }: ProgressProps) {
    return (
        <div className={cs(Styles.ProgressContainer, className)}>
            <div className={Styles.ProgressBar}>
                <div
                    className={Styles.ProgressFill}
                    style={{ width: `${percent}%` }} 
                />
            </div>
            <span className={Styles.Percentage}>%{percent}</span>
        </div>
    );
}

import { Switch, SwitchProps } from 'antd';
import cx from 'classnames';
import Styles from './Styles.module.scss';

export default function GSwitch({ className, style, ...props }: IGSwitch) {
    return (
        <div
            className={cx(Styles.SwitchContainer, className)}
            style={style}
        >
            <Switch {...props} />
        </div>
    );
}

interface IGSwitch extends SwitchProps {

}
